<template>

  <!-- Marketing Budget -->
  <div id="story-mode-marketing-budget">

    <!-- Main Navigation -->
    <main-navigation />
    <!-- /Main Navigation -->

    <!-- Container -->
    <div class="container-1200">
      <loader v-if="!loaded.marketingBudget" />

      <!-- Content -->
      <div
        v-if="loaded.marketingBudget"
        class="content"
        :class="{ 'position-relative': isMarketingBudgedCompleted }"
      >
        <v-app>
          <!-- Page Header -->
          <header class="page-header">

            <!-- Dashboard Header -->
            <dashboard-header
              :class="{ 'stamp': isMarketingBudgedCompleted }"
              :title="$t('steps.projections.items.marketingBudget')"
              :guide-visible="guideVisible"
              :completed-step-name="'marketingBudgetCompleted'"
              hide-quick-tour-button
              @toggle-guide="onGuideExampleVisible"
              @open-comp-dialog="openedDialogProcessCompleted"
              @open-info="guideVisible = true"
              @open-ai-buddy-drawer="aiBuddyDrawerVisible = true"
            />
            <!-- /Dashboard Header -->

            <!-- Divider -->
            <hr>
          <!-- /Divider -->

          </header>
        <!-- /Page Header -->
        </v-app>

        <!-- Height Holder For Sticky Header -->
        <div class="height-holder d-none d-md-block" />
        <!-- /Height Holder For Sticky Header -->

        <!-- Charts And Table Placeholders -->
        <div v-if="isMarketingElementsEmpty" class="table-view">
          <el-tabs v-model="activeName">

            <!-- Breakdown -->
            <el-tab-pane :label="$t('breakdown')" name="first">
              <div class="switcher-inner-wrapper">
                <div class="placeholder breakdown" />
              </div>
            </el-tab-pane>
            <!-- /Breakdown -->

            <!-- Timeline -->
            <el-tab-pane :label="$t('timeline')" name="second">
              <div class="switcher-inner-wrapper">
                <div class="placeholder timeline" />
              </div>
            </el-tab-pane>
            <!-- /Timeline -->

            <!-- Table -->
            <el-tab-pane :label="$t('table')" name="third">
              <div class="switcher-inner-wrapper">
                <div class="placeholder table" />
              </div>
            </el-tab-pane>
            <!-- /Table -->

          </el-tabs>
        </div>
        <!-- /Charts And Table Placeholders -->

        <!-- Charts And Table Filled -->
        <div v-if="!isMarketingElementsEmpty" class="table-view filled">
          <el-tabs v-model="activeName">

            <!-- Breakdown -->
            <el-tab-pane :label="$t('breakdown')" name="first">
              <div v-if="activeName === 'first'" class="bg-white">
                <distribution-of-marketing-budget
                  class="pt-4"
                  chart-animations
                />
              </div>
            </el-tab-pane>
            <!-- /Breakdown -->

            <!-- Timeline -->
            <el-tab-pane :label="$t('timeline')" name="second">
              <div v-if="activeName === 'second'" class="bg-white">
                <marketing-budget-over-time
                  class="pt-4"
                  chart-animations
                />
              </div>
            </el-tab-pane>
            <!-- /Timeline -->

            <!-- Table -->
            <el-tab-pane :label="$t('table')" name="third">
              <div v-if="activeName === 'third'" class="switcher-inner-wrapper">
                <marketing-budget-table class="pt-4" />
              </div>
            </el-tab-pane>
            <!-- /Table -->

          </el-tabs>
        </div>
        <!-- /Charts And Table Filled -->

        <v-app>
          <!-- Header -->
          <business-guide-header>
            <template #right>
              <erase-icon-button
                v-if="removeButtonVisible"
                @click="onEraseFromTemplate"
              />
              <business-guide-search
                v-if="!isMarketingElementsEmpty"
                v-model="searchValue"
                class="mx-1"
              />
              <!-- View Switcher -->
              <view-switcher
                v-if="isMarketingElementsEmpty === false"
                :active-view="$store.state.itemsView.marketingBudgetView"
                @activate-view="onActivateView"
              />
              <!-- /View Switcher -->
            </template>
          </business-guide-header>
          <!-- /Header -->
        </v-app>

        <!-- Grid Wrapper -->
        <div
          v-if="$store.state.itemsView.marketingBudgetView === 'grid'"
          class="grid-wrapper"
          :class="{ 'active': $store.state.itemsView.marketingBudgetView === 'grid' }"
        >
          <el-row>
            <el-col>
              <el-row :gutter="20" class="d-flex flex-wrap">
                <!-- Completed Overlay -->
                <completed-overlay
                  v-if="!canEdit || isMarketingBudgedCompleted"
                  @click="checkMarketingBudget"
                />
                <!-- /Completed Overlay -->
                <!-- Add Marketing Element -->
                <el-col :md="8" :xl="6" class="d-flex mb-4 width-lg-20">
                  <add-new-card
                    class="width-xl-20"
                    :title="$tc('addMarketingCost')"
                    :disabled="!canEdit || isMarketingBudgedCompleted"
                    min-height="345px"
                    @click="onAddMarketingBudget"
                  />
                </el-col>
                <!-- /Add Marketing Element -->

                <!-- Grid Items -->
                <el-col
                  v-for="marketingElement in marketingElementsToShowGrid"
                  :key="marketingElement.id"
                  class="d-flex mb-4 width-lg-20"
                  :md="8"
                  :xl="6"
                >
                  <div class="d-flex w-100" @click="checkMarketingBudget">
                    <set-up-and-projections-card
                      class="w-100"
                      :entity="marketingElement"
                      :name="marketingElement.name"
                      :date="marketingElementDate(marketingElement)"
                      :amount="marketingElementAmount(marketingElement)"
                      :frequency="marketingElement.intervalType === 'one_time' ? $t('pages.businessGuide.oneTime') : $t('pages.businessGuide.monthlyAverage')"
                      :type="`${marketingElement.categoryGroup}_marketing_budget`"
                      :disabled="isMarketingBudgedCompleted"
                      @edit="onEditMarketingElement"
                      @copy="onCopyMarketingElement"
                      @delete="onDeleteMarketingElement"
                    />
                    <!--                      :copy-disabled="marketingElement.category === marketingElement.categoryGroup"-->
                  </div>
                </el-col>
                <!-- /Grid Items -->
              </el-row>
              <!-- Show All -->
              <el-row v-if="idea.storyMode.forecast.marketingBudget.marketingElements.length > 4">
                <el-col>
                  <show-all-toggle
                    :show="showAllMarketingElementsGrid"
                    @click="showAllMarketingElementsGrid = !showAllMarketingElementsGrid"
                  />
                </el-col>
              </el-row>
              <!-- /Show All -->
            </el-col>
          </el-row>
        </div>
        <!-- /Grid Wrapper -->

        <!-- List Wrapper -->
        <div
          v-if="$store.state.itemsView.marketingBudgetView === 'list'"
          class="list-wrapper"
          :class="{ 'active': $store.state.itemsView.marketingBudgetView === 'list' }"
        >
          <el-row :gutter="20" class="d-flex flex-wrap">
            <!-- Completed Overlay -->
            <completed-overlay
              v-if="!canEdit || isMarketingBudgedCompleted"
              @click="checkMarketingBudget"
            />
            <!-- /Completed Overlay -->
            <!-- Add Marketing Element -->
            <el-col :md="12" class="d-flex mb-2">
              <add-new-card
                class="width-xl-20 py-2 py-md-0"
                :title="$tc('addMarketingCost')"
                :disabled="!canEdit || isMarketingBudgedCompleted"
                horizontal
                @click="onAddMarketingBudget"
              />
            </el-col>
            <!-- /Add Marketing Element -->

            <!-- Placeholder 1 -->
            <el-col v-if="isMarketingElementsEmpty && $breakpoint.mdAndUp" :md="12">
              <div class="placeholder blue">
                <div class="inner" />
              </div>
            </el-col>
            <!-- /Placeholder 1 -->

            <!-- Placeholder 2 -->
            <el-col v-if="isMarketingElementsEmpty && $breakpoint.mdAndUp" :md="12">
              <div class="placeholder blue">
                <div class="inner" />
              </div>
            </el-col>
            <!-- /Placeholder 2 -->

            <!-- Placeholder 3 -->
            <el-col v-if="isMarketingElementsEmpty && $breakpoint.mdAndUp" :md="12">
              <div class="placeholder yellow">
                <div class="inner" />
              </div>
            </el-col>
            <!-- /Placeholder 3 -->

            <!-- Tutorial Tooltip TODO - Currently disabled in if directive -->
            <tutorial-tooltip
              v-if="$store.state.user.tutorial.mainBottomBarCompleted === false && 1 === 2"
              :title="$tc('addMarketingCost', 2)"
              :text="'This is the star of <strong>your show</strong>! Lorem ipsum dolor sit amet, consectetur, adipiscing elit praesent.'"
              :tutorial-name="'directCostsCompleted'"
            />
            <!-- /Tutorial Tooltip -->

            <!-- List Items -->
            <el-col
              v-for="marketingElement in marketingElementsToShowList"
              :key="marketingElement.id"
              class="d-flex"
              :md="12"
            >
              <div class="d-flex w-100" @click="checkMarketingBudget">
                <set-up-and-projections-card
                  class="mb-2 w-100"
                  :entity="marketingElement"
                  :name="marketingElement.name"
                  :date="marketingElementDate(marketingElement)"
                  :amount="marketingElementAmount(marketingElement)"
                  :frequency="marketingElement.intervalType === 'one_time' ? $t('pages.businessGuide.oneTime') : $t('pages.businessGuide.monthlyAverage')"
                  :type="`${marketingElement.categoryGroup}_marketing_budget`"
                  :disabled="isMarketingBudgedCompleted"
                  view="list"
                  @edit="onEditMarketingElement"
                  @copy="onCopyMarketingElement"
                  @delete="onDeleteMarketingElement"
                />
                <!--                  :copy-disabled="marketingElement.category === marketingElement.categoryGroup"-->
              </div>
            </el-col>
            <!-- /List Items -->
          </el-row>
          <!-- Show All -->
          <el-row v-if="idea.storyMode.forecast.marketingBudget.marketingElements.length > 7">
            <el-col>
              <show-all-toggle
                :show="showAllMarketingElementsList"
                @click="showAllMarketingElementsList = !showAllMarketingElementsList"
              />
            </el-col>
          </el-row>
          <!-- /Show All -->
        </div>
        <!-- /List Wrapper -->

      </div>
      <!-- /Content -->

      <!-- Add More Details -->
      <add-more-details
        :category="'forecast'"
        :step="'marketing-budget'"
        :concept-name="'marketingBudgetMoreDetails'"
        :completed-step-name="'marketingBudgetCompleted'"
      />
      <!-- /Add More Details -->

    </div>
    <!-- /Container -->

    <!-- Main Bottom Bar -->
    <main-bottom-bar
      :back="backNavigation"
      :next="nextNavigation"
      :completed-step-name="'marketingBudgetCompleted'"
      :step-filled="idea.storyMode.forecast.marketingBudget.marketingElements.length > 0"
      @complete-step="onCompleteStep"
    />
    <!-- /Main Bottom Bar -->

    <!-- Info Guide Drawer -->
    <info-guide-drawer
      type="marketingBudgetExamples"
      :visible="guideVisible"
      @close="guideVisible = false"
    />
    <!-- /Info Guide Drawer -->

    <!-- Ai Buddy Drawer -->
    <ai-buddy-drawer
      :visible="aiBuddyDrawerVisible"
      type="marketing-budget"
      @close="aiBuddyDrawerVisible = false"
    />
    <!-- /Ai Buddy Drawer -->

    <!-- Dialog Add Marketing Budget -->
    <dialog-add-marketing-budget
      v-if="loaded.marketingBudget || dialogAddMarketingElementVisible"
      :marketing-element-action="marketingElementAction"
      :dialog-add-marketing-element-visible="dialogAddMarketingElementVisible"
      :on-edit-marketing-element="onEditMarketingElement"
      :on-copy-marketing-element="onCopyMarketingElement"
      :on-delete-marketing-element="onDeleteMarketingElement"
      @close-dialog-add-marketing-element="onCloseDialogAddMarketingElement"
      @close-dialog-on-button="dialogAddMarketingElementVisible = false"
      @on-update-marketing-budget-view="getMarketingBudget"
    />
    <!-- /Dialog Add Marketing Budget -->

    <!-- Dialog Process Completed -->
    <dialog-process-completed
      :dialog-process-completed-visible="dialogProcessCompletedVisible"
      @close-dialog-process-completed="onCloseDialogProcessCompleted"
    />
    <!-- /Dialog Process Completed -->

    <!-- Dialog Remove Examples -->
    <dialog-remove-examples
      :visible="dialogRemoveExamplesVisible"
      :loading="removeExamplesLoading"
      @no="dialogRemoveExamplesVisible = false"
      @yes="deleteExamples"
    />
    <!-- /Dialog Remove Examples -->

  </div>
  <!-- /Marketing Budget -->

</template>

<script>
import AddMoreDetails from '@/views/Home/StoryMode/Components/AddMoreDetails'
import AddNewCard from '@/components/_v2/AddNewCard'
import AiBuddyDrawer from '@/views/Home/StoryMode/Components/AiBuddy/AiBuddyDrawer.vue'
import BusinessGuideHeader from '@/components/_v2/Common/BusinessGuideHeader'
import CompletedOverlay from '@/views/Home/StoryMode/Components/CompletedOverlay'
import DashboardHeader from '@/views/Home/StoryMode/Components/DashboardHeader'
import DialogAddMarketingBudget from './Dialogs/DialogAddMarketingBudget'
import DialogProcessCompleted from '@/views/Home/StoryMode/Components/Dialogs/DialogProcessCompleted'
import DialogRemoveExamples from '@/views/Home/StoryMode/Components/DialogRemoveExamples'
import DistributionOfMarketingBudget from '@/views/Home/StoryMode/FinancialPlan/Components/Expenses/MarketingCost/DistributionOfMarketingBudget'
import EraseIconButton from '@/components/_v2/EraseIconButton'
import Loader from '@/components/Loader'
import MainBottomBar from '@/views/Home/StoryMode/Components/MainBottomBar/MainBottomBar'
import MainNavigation from '@/views/Home/StoryMode/Components/MainNavigation/MainNavigation'
import MarketingBudgetOverTime from '@/views/Home/StoryMode/FinancialPlan/Components/Expenses/MarketingCost/MarketingBudgetOverTime'
import MarketingBudgetTable from '@/views/Home/StoryMode/Forecast/MarketingBudget/Tables/MarketingBudgetTable'
import MixinApiCalculations from '@/mixins/apiCalculations'
import MixinCurrencies from '@/mixins/currencies'
import MixinDate from '@/mixins/date'
import MixinDeleteIdeaExamples from '@/mixins/deleteIdeaExamples'
import MixinDialog from '@/mixins/dialog'
import MixinEraseTemplate from '@/mixins/eraseTemplate'
import MixinGuide from '@/mixins/guide'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import MixinProcessCompleted from '@/mixins/processCompleted'
import MixinUserSubscription from '@/mixins/permissions/userSubscription'
import SetUpAndProjectionsCard from '@/views/Home/StoryMode/Components/Cards/SetUpAndProjectionsCard'
import ShowAllToggle from '@/views/Home/StoryMode/Components/ShowAllToggle'
import TutorialTooltip from '@/views/Home/Components/TutorialTooltip'
import ViewSwitcher from '@/components/_v2/ViewSwitcher'

import { mapActions, mapGetters, mapState } from 'vuex'
import BusinessGuideSearch from '@/views/Home/Components/BusinessGuideSearch.vue'
import InfoGuideDrawer from '@/views/Home/StoryMode/Components/Example/InfoGuideDrawer.vue'

export default {
  name: 'MarketingBudget',

  components: {
    AiBuddyDrawer,
    InfoGuideDrawer,
    BusinessGuideSearch,
    AddMoreDetails,
    AddNewCard,
    BusinessGuideHeader,
    CompletedOverlay,
    DashboardHeader,
    DialogAddMarketingBudget,
    DialogProcessCompleted,
    DialogRemoveExamples,
    DistributionOfMarketingBudget,
    EraseIconButton,
    Loader,
    MainBottomBar,
    MainNavigation,
    MarketingBudgetOverTime,
    MarketingBudgetTable,
    SetUpAndProjectionsCard,
    ShowAllToggle,
    TutorialTooltip,
    ViewSwitcher
  },

  mixins: [
    MixinCurrencies,
    MixinDate,
    MixinApiCalculations,
    MixinProcessCompleted,
    MixinGuide,
    MixinDeleteIdeaExamples,
    MixinIdeaRoles,
    MixinEraseTemplate,
    MixinDialog,
    MixinUserSubscription
  ],

  // beforeRouteEnter (to, from, next) {
  //   next(vm => {
  //     if (vm.subscriptionIsFreeTrial) next({ name: 'home' })
  //   })
  // },

  data () {
    return {
      aiBuddyDrawerVisible: false,
      searchValue: '',
      dialogRemoveExamplesVisible: false,
      removeExamplesLoading: false,
      activeName: 'first',
      dialogAddMarketingElementVisible: false,
      guideVisible: false,
      marketingElementAction: null,
      showAllMarketingElementsGrid: false,
      showAllMarketingElementsList: false,
      dialogProcessCompletedVisible: false
    }
  },

  computed: {
    ...mapState(['idea']),

    ...mapGetters('idea', {
      marketingElements: 'getMarketingElements',
      getStepIsCompleted: 'getStepIsCompleted',
      getHasItemsFromTemplate: 'getHasItemsFromTemplate'
    }),

    isMarketingElementsEmpty () {
      return !this.marketingElements.length
    },

    marketingElementsToShowGrid () {
      if (this.searchValue !== '' && this.searchValue) {
        return this.filterMarketingBudget
      }

      return this.showAllMarketingElementsGrid === true
        ? this.marketingElements
        : this.marketingElements.slice(0, 4)
    },

    marketingElementsToShowList () {
      if (this.searchValue !== '' && this.searchValue) {
        return this.filterMarketingBudget
      }

      return this.showAllMarketingElementsList === true
        ? this.marketingElements
        : this.marketingElements.slice(0, 7)
    },

    filterMarketingBudget () {
      return this.marketingElements.filter(item => item.name.toUpperCase().includes(this.searchValue.toUpperCase()))
    },

    isMarketingBudgedCompleted () {
      return this.getStepIsCompleted('marketingBudgetCompleted')
    },

    ideaStartingFrom () {
      return this.idea.ideaSettings.startingFrom
    },

    removeButtonVisible () {
      return this.canEdit && this.getHasItemsFromTemplate('getMarketingElements')
    },

    backNavigation () {
      return {
        title: this.$t('pages.businessGuide.navigation.directCosts'),
        route: 'business-guide-projections-direct-costs'
      }
    },

    nextNavigation () {
      return {
        title: this.$t('pages.businessGuide.navigation.otherOverheads'),
        route: 'business-guide-projections-other-overheads'
      }
    }
  },

  watch: {
    ideaStartingFrom () {
      this.getMarketingBudget()
    }
  },

  async created () {
    await this.getMarketingBudget()
    await this.getMarketingBudgetMoreDetails()
    this.openGuide('marketingBudgetGuide')
  },

  methods: {
    ...mapActions('idea', [
      'getMarketingBudgetMoreDetails',
      'deleteStoryModeMarketingElement',
      'deleteIdeaExamples'
    ]),

    checkMarketingBudget () {
      if (!this.canEdit) return
      if (this.isMarketingBudgedCompleted) {
        this.dialogProcessCompletedVisible = true
      }
    },

    onAddMarketingBudget () {
      if (!this.canEdit) return
      this.dialogAddMarketingElementVisible = true
    },

    onCloseDialogAddMarketingElement () {
      this.dialogAddMarketingElementVisible = false
      this.marketingElementAction = null

      // Workaround to update computed property startupCostsToShowGrid & startupCostsToShowList
      this.updateMarketingBudgetView()
    },

    updateMarketingBudgetView () {
      this.showAllMarketingElementsList = !this.showAllMarketingElementsList
      this.showAllMarketingElementsList = !this.showAllMarketingElementsList
      this.showAllMarketingElementsGrid = !this.showAllMarketingElementsGrid
      this.showAllMarketingElementsGrid = !this.showAllMarketingElementsGrid
    },

    marketingElementDate (marketingElement) {
      if (marketingElement.whenWillItOccur) {
        return this.getFormattedDate(marketingElement.whenWillItOccur)
      }

      return `${this.getFormattedDate(marketingElement.starts)} - ${this.getFormattedDate(marketingElement.ends)}`
    },

    marketingElementAmount (marketingElement) {
      if (marketingElement.average && marketingElement.amountIntervalType !== 'one_time') {
        return this.getFormattedAmount(marketingElement.average)
      }

      return this.getFormattedAmount(marketingElement.amount)
    },

    onGuideExampleVisible () {
      this.guideVisible = !this.guideVisible
    },

    onActivateView (view) {
      this.$store.commit('itemsView/setMarketingBudgetView', view)
    },

    onEditMarketingElement (marketingElement) {
      this.marketingElementAction = Object.assign({}, marketingElement)
      this.dialogAddMarketingElementVisible = false
      this.dialogAddMarketingElementVisible = true
      this.scrollToTop()
    },

    onCopyMarketingElement (marketingElement) {
      this.marketingElementAction = Object.assign({}, marketingElement)
      this.marketingElementAction.id = 0
      this.dialogAddMarketingElementVisible = true
      this.scrollToTop()
    },

    onEraseFromTemplate () {
      if (this.isMarketingBudgedCompleted) {
        this.openedDialogProcessCompleted()

        return
      }
      this.dialogRemoveExamplesVisible = true
    },

    onDeleteMarketingElement (marketingElement) {
      this.loaded.marketingBudget = false
      this.deleteStoryModeMarketingElement(marketingElement.id)
        .then(() => {
          this.getMarketingBudget()
        })

      // Return to list view, if there are no Marketing Elements
      if (this.idea.storyMode.forecast.marketingBudget.marketingElements.length === 0) {
        this.onActivateView('list')
      }
    },

    deleteExamples () {
      this.removeExamplesLoading = true
      this.deleteIdeaExamples('marketing-elements')
        .then(response => {
          const { type, ids } = response
          this.deleteFromState({ type, ids })
          this.getMarketingBudget()
          this.dialogRemoveExamplesVisible = false
          this.removeExamplesLoading = false
        }).catch(_ => { this.removeExamplesLoading = false })
    }
  }
}
</script>

<style scoped>
  #story-mode-marketing-budget {
    margin-left: 0;
  }
</style>
