<template>
  <chart-component>
    <!-- Title -->
    <template #title>
      {{ $t('charts.distributionOfMarketingCosts') }}
    </template>
    <!-- /Title -->
    <!-- Subtitle -->
    <template #subtitle>
      {{ $t('pages.businessGuide.monthlyAverage') }}
    </template>
    <!-- /Subtitle -->
    <!-- Breakdown Total Switcher -->
    <template #switcher>
      <breakdown-total-switcher
        class="switcher"
        @change="showTotals = $event"
      />
    </template>
    <!-- /Breakdown Total Switcher -->
    <!-- Chart -->
    <template #chart>
      <distribution-of-marketing-budget-chart
        hide-title
        :show-totals="showTotals"
        :chart-animations="chartAnimations"
      />
    </template>
    <!-- /Chart -->
    <!-- Right side -->
    <template #right-side>
      <chart-component-right-side>
        <total-amount
          class="mb-3"
          :amount="totalAmount"
          :text="$t('totalMonthlyAverage')"
        />
        <div>
          <p class="m-0 text-small mb-4">
            {{ $t('topThreeAverage') }}
          </p>
          <template v-for="(marketingElement, index) in topMarketingBudget">
            <year-info
              :key="index"
              class="mb-3"
              :avatar-text="index + 1"
              :text="marketingElement.name"
              circle
              :money="marketingElement.average"
            />
          </template>
        </div>
      </chart-component-right-side>
    </template>
    <!-- /Right side -->
  </chart-component>
</template>

<script>
import BreakdownTotalSwitcher from '@/components/Charts/Breakdown/BreakdownTotalSwitcher'
import ChartComponent from '@/views/Home/StoryMode/FinancialPlan/Components/ChartComponent'
import ChartComponentRightSide from '@/views/Home/StoryMode/FinancialPlan/Components/ChartComponentRightSide'
import DistributionOfMarketingBudgetChart from '@/views/Home/StoryMode/Forecast/MarketingBudget/Charts/DistributionOfMarketingBudgetChart'
import TotalAmount from '@/views/Home/StoryMode/FinancialPlan/Components/TotalAmount'
import YearInfo from '@/views/Home/StoryMode/FinancialPlan/Components/YearInfo'

export default {
  name: 'DistributionOfMarketingBudget',

  components: {
    ChartComponentRightSide,
    ChartComponent,
    BreakdownTotalSwitcher,
    DistributionOfMarketingBudgetChart,
    TotalAmount,
    YearInfo
  },

  props: {
    chartAnimations: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      showTotals: false
    }
  },

  computed: {
    totalAmount () {
      let result = 0

      if (this.$store.state.idea.storyMode.forecast.marketingBudget.breakdown.totalBudget) {
        result = this.$store.state.idea.storyMode.forecast.marketingBudget.breakdown.totalBudget
      }

      return result
    },

    topMarketingBudget () {
      let result = []

      if (this.$store.state.idea.storyMode.forecast.marketingBudget.breakdown.sorted) {
        result = this.$store.state.idea.storyMode.forecast.marketingBudget.breakdown.sorted
      }

      return result
    }
  }
}
</script>
